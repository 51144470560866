<template>
  <!-- 终端管理页面 -->
  <div>
    <!-- 头部开始 -->
    <el-row class="pur-top">
      <el-col :span="3">
        <span class="pur-size">{{ purTitle }}</span></el-col>
        <el-col :span="21" class="pur-right">
            <el-button size="small" @click="openPur">删除</el-button>
            <el-button size="small" style="margin-left: 24px" @click="addPurs" type="primary">新增</el-button>
            <el-input
            @keyup.enter.native="search"
            size="small"
            placeholder="请输入内容"
            prefix-icon="el-icon-search"
            v-model="purSearch">
            </el-input>
        </el-col>
    </el-row>
    <!-- 头部结束 -->
    <!-- 内容开始 -->
    <div class="pur-nav"
    v-loading="loading"
    element-loading-text = "加载中...">
      <!-- 表格 -->
      <div class="pur-table">
        <el-table
          height="100%"
          border
          :data="tableData"
          :header-cell-style="{ background: '#F6F6F6', color: '#333' }"
          style="width: 100%;"
          @selection-change="purChange">
            <el-table-column type="selection"></el-table-column>
            <el-table-column prop="id" v-if="show">
            </el-table-column>
            <el-table-column label="终端型号" :show-overflow-tooltip="true" prop="terminalModel">
            </el-table-column>
            <el-table-column label="终端SN码" :show-overflow-tooltip="true" prop="terminalId">
            </el-table-column>
            <el-table-column label="设备卡号" :show-overflow-tooltip="true" prop="terminalCardNb">
            </el-table-column>
            <el-table-column label="设备类型" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                  <span v-if="scope.row.equipmentType == 1">定位设备</span>
                  <span v-if="scope.row.equipmentType == 2">视频设备</span>
              </template>
            </el-table-column>
            <el-table-column label="备注车牌" :show-overflow-tooltip="true" prop="licensePlateRemark">
            </el-table-column>
            <el-table-column label="用户账号" :show-overflow-tooltip="true" prop="account">
            </el-table-column>
            <el-table-column label="设备到期时间" :show-overflow-tooltip="true" prop="equipmentExpirationTime">
            </el-table-column>
            <el-table-column label="接收定位数据" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                  <span v-if="scope.row.isReceiveMsg">开启</span>
                  <span v-else>关闭</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作" width="100" fixed="right">
              <template slot-scope="scope">
                <el-button @click="EditPurs(scope.row)" size="mini">编辑</el-button>
              </template>
            </el-table-column>
          <!-- 暂无数据 -->
          <div slot="empty" class="dataAvailable">
              <div class="dataAva">
                <img src="../assets/zanwushuju.png" alt="">
                <p>暂无数据，您可以新建试试</p>
                <el-button size="small" style="margin-left: 24px" @click="addPurs">新增</el-button>
              </div>
          </div>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="pur-pages">
        <el-pagination
        background
        :page-sizes="[10, 30, 50, 100, 200]"
        :page-size.sync="size"
        :pager-count="5"
        :current-page.sync="current"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @current-change='getpurcon'
        @size-change='getpurcon'>
        </el-pagination>
      </div>
      <!-- 新建终端信息弹窗 -->
      <el-dialog
        :closeOnClickModal="false"
        title="新建终端信息"
        :visible.sync="paymentShow"
        width="35%"
        :before-close="paymentShowClose">
          <el-form style="width:95%;" :model="AddData" :rules="paymentrules" ref="AddData" size="small" label-width="100px">
            <el-form-item label="终端型号" prop="terminalModel">
              <el-input v-model="AddData.terminalModel"></el-input>
            </el-form-item>
            <el-form-item label="终端SN码" prop="terminalId">
              <el-input v-model="AddData.terminalId"></el-input>
            </el-form-item>
            <el-form-item label="设备卡号" prop="terminalCardNb">
              <el-input v-model="AddData.terminalCardNb"></el-input>
            </el-form-item>
            <el-form-item label="设备类型">
              <el-radio v-model="AddData.equipmentType" :label="1">定位设备</el-radio>
              <el-radio v-model="AddData.equipmentType" :label="2">视频设备</el-radio>
            </el-form-item>
            <el-form-item label="备注车牌" prop="licensePlateRemark">
              <el-input v-model="AddData.licensePlateRemark"></el-input>
            </el-form-item>
            <el-form-item label="用户账号">
              <el-select v-model="AddData.fence" clearable filterable placeholder="请选择用户账号" style="width:100%;">
                  <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.account"
                  :value="item.id + ','+ item.account">
                  </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备到期时间">
              <el-date-picker
              style="width:100%;"
                v-model="AddData.equipmentExpirationTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="接收定位数据">
              <el-switch v-model="AddData.isReceiveMsg"></el-switch>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="paymentsubmitm('AddData')" style="float:right;margin:5% 0 0 2%;">确定</el-button>
              <el-button @click="paymentresetForm" style="float:right;margin:5% 0 0 2%;">取消</el-button>
            </el-form-item>
          </el-form>
      </el-dialog>
      <!-- 编辑终端信息弹窗 -->
      <el-dialog
        :closeOnClickModal="false"
        title="编辑终端信息"
        :visible.sync="editpaymentShow"
        width="35%"
        :before-close="editpaymentShowClose">
          <el-form style="width:95%;" :model="editData" :rules="editpaymentrules" ref="AddData" size="small" label-width="100px">
            <el-form-item label="终端型号" prop="terminalModel">
              <el-input v-model="editData.terminalModel"></el-input>
            </el-form-item>
            <el-form-item label="终端SN码" prop="terminalId">
              <el-input v-model="editData.terminalId"></el-input>
            </el-form-item>
            <el-form-item label="设备卡号" prop="terminalCardNb">
              <el-input v-model="editData.terminalCardNb"></el-input>
            </el-form-item>
            <el-form-item label="设备类型">
              <el-radio v-model="editData.equipmentType" :label="1">定位设备</el-radio>
              <el-radio v-model="editData.equipmentType" :label="2">视频设备</el-radio>
            </el-form-item>
            <el-form-item label="备注车牌" prop="licensePlateRemark">
              <el-input v-model="editData.licensePlateRemark"></el-input>
            </el-form-item>
            <el-form-item label="用户账号">
              <el-select v-model="editData.fence" filterable clearable placeholder="请选择用户账号" style="width:100%;">
                  <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.account"
                  :value="item.id + ','+ item.account">
                  </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备到期时间">
              <el-date-picker
              style="width:100%;"
                v-model="editData.equipmentExpirationTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="接收定位数据">
              <el-switch v-model="editData.isReceiveMsg"></el-switch>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="editpaymentsubmitm('AddData')" style="float:right;margin:5% 0 0 2%;">确定</el-button>
              <el-button @click="editpaymentresetForm" style="float:right;margin:5% 0 0 2%;">取消</el-button>
            </el-form-item>
          </el-form>
      </el-dialog>
    </div>
    <!-- 内容结束 -->
  </div>
</template>
<script>
export default {
    data() {
        return {
          loading:false,
          current:1,              // 当前页数
          size:10,                // 显示条数
          total:0,                // 总条数
          delmul:[],              // 批量删除
          show:false,
          purTitle: "",           // 标题
          purSearch: "",          // 搜索        
          multipleSelection: [],  // 选中数据
          tableData: [],          // 表格数据  
          // 新建终端信息弹窗
          paymentShow:false,
          AddData:{
            equipmentType: 1,
            isReceiveMsg:false
          },
          paymentrules: {
              terminalModel: [
                { required: true, message: '请输入终端型号', trigger: 'blur' }
              ],
              terminalId: [
                { required: true, message: '请输入终端SN码', trigger: 'blur' },
                { pattern: /^[0-9]*$/, message: '终端SN码为数字', trigger: 'blur'} 
              ],
              terminalCardNb: [
                { required: true, message: '请输入设备卡号', trigger: 'blur' },
              ],
              licensePlateRemark: [
                { pattern: /(^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$)/, message: '请输入正确的车牌号', trigger: 'blur'}
              ],
          },
          options: [],
          // 终端编辑
          editpaymentShow:false,
          editData:{
            equipmentType: 1,
            isReceiveMsg:false
          },
          editpaymentrules: {
            terminalModel: [
              { required: true, message: '请输入终端型号', trigger: 'blur' }
            ],
            terminalId: [
              { required: true, message: '请输入终端SN码', trigger: 'blur' },
              { pattern: /^[0-9]*$/, message: '终端SN码为数字', trigger: 'blur'} 
            ],
            terminalCardNb: [
              { required: true, message: '请输入设备卡号', trigger: 'blur' }, 
            ],
            licensePlateRemark: [
              { pattern: /(^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$)/, message: '请输入正确的车牌号', trigger: 'blur'}
            ],
          },
        };
    },
    created() {},
    mounted(){
      this.purTitle = this.$route.meta.title;
      this.getpurcon();
    },
    methods: {
      // 表格数据
      getpurcon(){
        this.loading = true;
        // 传参
        var listByPageData = {
          searchStr:this.purSearch,
          page:this.current,
          size:this.size
        }
        // 渲染表格
        this.api.Terminal.all(listByPageData)
        .then(res=>{
          this.loading = false;
          if(res.data.code == 200){
              this.tableData = res.data.data.dataList;
              this.total = res.data.data.totalCount;
          }
        })
      },
      // 搜索功能
      search() {
        this.tableData = [];
        this.getpurcon();
        this.current = 1;
      },
      // 表格选中数据
      purChange(val) {
        this.multipleSelection = val;
        this.delmul = [];
        for(let i=0;i<this.multipleSelection.length;i++){
          this.delmul.push(this.multipleSelection[i].id) 
        }
      },
      // 账号列表
      road(){
        let datas = {
          applys:"2",
          searchStr:"",
          level:1
        }
        this.api.User.list(datas)
        .then(res=>{
          if(res.data.code == 200){
            this.options = res.data.data;
          }
        })
      },
      // 新建终端信息
      addPurs(){
        this.paymentShow = true;
        this.road();
      },
      // 新建终端信息取消
      paymentShowClose(){
        this.paymentShow = false;
        this.AddData = {
            equipmentType: 1,
            isReceiveMsg:false
        }
      },
      // 取消新建终端信息提交
      paymentresetForm() {
        this.paymentShow = false;
        this.AddData = {
            equipmentType: 1,
            isReceiveMsg:false
        }
      },
      // 新建终端信息提交
      paymentsubmitm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if(this.AddData.fence){
              let arrs = this.AddData.fence.split(',');
              this.AddData.userId = arrs[0];
              this.AddData.account = arrs[1];
            }
            this.api.Terminal.add(this.AddData)
            .then(res=>{
              if(res.data.code == 200){
                this.$message({
                  type: 'success',
                  message: '保存成功!'
                });
                this.AddData = {
                  equipmentType: 1,
                  isReceiveMsg:false
                }
                this.tableData = [];
                this.getpurcon();
                this.current = 1;
                this.paymentShow = false;
              }
            })
          }
        });
      },
      // 编辑终端信息
      EditPurs(data){
        this.editpaymentShow = true;
        this.road();
        data.fence = (data.userId==0?'':data.userId + ','+ data.account).toString();
        this.editData = JSON.parse(JSON.stringify(data));
        console.log(this.editData)
        
        // if(this.editData.userId && this.editData.account){
          
          console.log(this.editData.fence)
        // }
      },
      // 编辑终端信息取消
      editpaymentShowClose(){
        this.editpaymentShow = false;
        this.editData = {}
      },
      // 取消编辑终端信息提交
      editpaymentresetForm() {
        this.editpaymentShow = false;
        this.editData = {}
      },
      // 编辑终端信息提交
      editpaymentsubmitm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log(this.editData.fence)
            if(this.editData.fence){
              let arrs = this.editData.fence.split(',');
              this.editData.userId = arrs[0];
              this.editData.account = arrs[1];
            }else{
              this.editData.userId = 0;
              this.editData.account = '';
            }
            this.api.Terminal.edit(this.editData)
            .then(res=>{
              if(res.data.code == 200){
                this.$message({
                  type: 'success',
                  message: '编辑成功!'
                });
                this.editData = {
                  equipmentType: 1,
                  isReceiveMsg:false
                }
                this.tableData = [];
                this.getpurcon();
                this.current = 1;
                this.editpaymentShow = false;
              }
            })
          }
        });
      },
      // 删除功能
      openPur() {
          if(this.multipleSelection == ""){
            this.$message({
                type: 'warning',
                message: '请选择要删除的数据!'
            });
          }else{
            this.$confirm('此操作将永久删除该终端信息, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
              this.api.Terminal.del(this.delmul)
              .then(res=>{
                if(res.data.code == 200){
                  this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
                  this.tableData = [];
                  this.getpurcon();
                  this.current = 1;
                  if(this.tableData == []){
                      this.dataAva = true;
                  }   
                }
              })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
          }
      },
    },
};
</script>
<style lang="less" scoped>
// 头部开始
.pur-top {
  width: 100%;
  height: 50px;
  padding: 9px 24px 9px 32px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
  border: 1px solid rgba(102, 102, 102, 0.15);
  .pur-size {
    font-size: 16px;
    line-height: 32px;
    color: #333;
    font-weight: 600;
  }
  .pur-right {
    .el-input {
      width: 200px;
      float: right;
    }
    .el-button {
      margin-left: 16px;
      float: right;
    }
  }
}
// 头部结束
// 内容开始
.pur-nav {
  width: calc(100% - 32px);
  height: calc(100vh - 142px);
  background-color: #fff;
  margin: 8px;
  box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
  padding: 8px;
  .pur-table{
    width:100%;
    height: 94%;
  } 
  // 暂无数据样式开始
  .dataAvailable{
    width: 100%;
    height: 245px;
    position: relative;
    margin-top:185px;
    margin-bottom:279px;
    .dataAva{
      position: absolute;
      top:75%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      img{
        width: 371px;
        height: 200px;
        margin: auto;
      }
      p{
        font-size: 16px;
        line-height: 24px;
        color: #333;
      }
      .el-button{
        margin-bottom:100px;
      }
    }
  }
  // 暂无数据样式结束
  .pur-pages {
    width: 100%;
    height: 6%;
    padding: 6px 0 0 12px;
    box-sizing:border-box;
    .el-pagination {
      float: right;
    }
  }
}
// 内容结束
</style>